import {
    atom,
} from "jotai"
import { RESET } from "jotai/utils"
import { focusAtom } from 'jotai-optics'

// import dataFile from "../data/tiles.json"
import { SpeechConfigData, TileData, Element, Path, RuntimeConfigData, Theme } from "./coreTypes"
import { Locale } from "typesafe-i18n/types/runtime/src/core.mjs"
import { getGridData } from "src/utils/data"
import { sendMessageTop } from "src/utils/messages"
import { atomWithStorage } from 'src/utils/atoms';
import { merge } from "src/utils/object"

export const INIT: unique symbol = Symbol("INIT");

export const _inputAtom = atom<TileData[]>([])
export const inputAtom = atom(
    (get) => get(_inputAtom),
    (get, set, value: TileData | typeof RESET) => {
        if (value == RESET) {
            set(_inputAtom, [])
            return
        }
        set(_inputAtom, get(_inputAtom).concat(value as TileData))
    }
)

// default env variables, but can be overrite by habitat(widget) props in App.tsx
const _runtimeConfigAtom = atomWithStorage<Partial<RuntimeConfigData>>('config', {
    baseURL: 'https://pictoassets.epi3.co/', // default prefix, hardcoded
    overrideUserdata: false,
    logoLabel: 'Picto Access',
    lang: '',
    theme: 'light',
    grid: {
        cols: { xxs: 3, xs: 4, sm: 6, md: 8, lg: 10, '2xl': 12 },
        bpx: { xxs: 0, xs: 450, sm: 640, md: 768, lg: 1024, '2xl': 1536 },
        height: 100,
        minH: 1,
        minW: 1,
        maxW: 4,
        maxH: 4,
    },
    speech: {
        pitch: 1,
        rate: 1,
        voiceIndex: -1,
        speakWord: true,
    }
})

export const resetRuntimeConfigAtom = atom(true)
export const runtimeConfigAtom = atom(
    (get) => get(_runtimeConfigAtom),
    (get, set, value: Partial<RuntimeConfigData> | typeof RESET) => {
        if (value == RESET) {
            set(_runtimeConfigAtom, value as any)
            set(resetRuntimeConfigAtom, true)
        } else {
            set(_runtimeConfigAtom, merge(
                get(_runtimeConfigAtom),
                value
            ))
            if (value.lang) {
                set(langAtom, value.lang)
            }
        }
    }
)

export const speechConfigAtom = focusAtom<Partial<RuntimeConfigData>, SpeechConfigData, void>(_runtimeConfigAtom, (optic) => optic.prop('speech'))
export const langAtom = focusAtom<Partial<RuntimeConfigData>, Locale | '', void>(_runtimeConfigAtom, (optic) => optic.prop('lang'))

const _themeAtom = focusAtom<Partial<RuntimeConfigData>, Theme, void>(_runtimeConfigAtom, (optic) => optic.prop('theme'))
export const themeAtom = atom(
    (get) => {
        const theme = get(_themeAtom)
        if (typeof window != 'undefined')
            document.documentElement.setAttribute("data-theme", theme);
        sendMessageTop({ type: 'theme', data: theme })
        return theme
    },
    (get, set, value: Theme) => {
        set(_themeAtom, value)
        document.documentElement.setAttribute("data-theme", value);
        sendMessageTop({ type: 'theme', data: value })
    }
)

const _gridAtom = atomWithStorage<Element[]>('grid', [])
export const gridAtom = atom(
    (get) => {
        return get(_gridAtom)
    },
    (get, set, value: Element[] | typeof RESET | typeof INIT) => {
        const old = get(_gridAtom)
        const configs = get(runtimeConfigAtom)
        const getData = () => {
            getGridData({
                url: configs.baseURL,
                workspace: configs.workspace,
            }).then(data => {
                set(_gridAtom, data)
            })
                .catch(err => {
                    console.log("GET screens from server error:", err)
                    throw err
                })
        }
        // console.log(old, value)
        switch (value) {
            case INIT:
                // console.log(configs, old)
                if (configs.overrideUserdata || old?.length == 0) {
                    getData()
                }
                break
            case RESET:
                set(_gridAtom, value)
                getData()
                break
            default:
                set(_gridAtom, value as Element[])
        }
    }
)
// gridAtom.onMount = (commit) => {
//     commit(null)
// }

export const openMenuAtom = atom<boolean>(false)
export const editModeAtom = atom<boolean>(false)
export const dimModeAtom = atom((get) => get(openMenuAtom) && !get(editModeAtom))
export const colsAtom = atom(10)
export const breakpointAtom = atom('lg')
export const defaultScreen = 'home'
export const screenNameAtom = atom<string>(defaultScreen)
export const currentPathAtom = atom<Path>([])