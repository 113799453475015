import Color from "color"
import { getDefaultStore } from "jotai"
import { themeAtom } from "src/state"

export function generateForegroundColorFrom(input, percentage = 0.8) {
    const inColor = Color(input)
    let canditate: Color
    if (inColor.isDark()) {
        canditate = inColor.mix(Color("white"), percentage).saturate(10)
    } else {
        canditate = inColor.mix(Color("black"), percentage).saturate(10)
    }

    // test contrast, according to the WCAG 2.1 guidelines
    if (canditate.contrast(inColor) < 4.5) {
        canditate = inColor.grayscale()
    }

    if (canditate.contrast(inColor) < 4.5) {
        canditate = Color('white')
    }

    if (canditate.contrast(inColor) < 4.5) {
        canditate = Color('black')
    }

    // extreme
    if (canditate.contrast(inColor) < 4.5) {
        canditate = inColor.negate()
    }

    return canditate.hex()
}

export function getColor(color: string, mix = false) {
    const defaultStore = getDefaultStore()
    const theme = defaultStore.get(themeAtom)
    const defaultColor = theme == 'dark' ? { value: '#171717', per: 10 } : { value: '#FFF', per: 50 }

    let outColor
    try {
        outColor = color && color[0] == '#' ?
            Color(color) :
            Color(color || defaultColor.value)
        // outColor = mix ? outColor.lightness(defaultColor.per) : outColor
        if (mix && theme == 'dark')
            outColor = outColor.lightness(defaultColor.per)
    } catch {
        outColor = Color(defaultColor.value)
    }
    return outColor.hex()
}