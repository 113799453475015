import { useState, useEffect } from 'react'
import { navigatorDetector } from 'typesafe-i18n/detectors'
import TypesafeI18n from './i18n/i18n-react'
import { detectLocale } from './i18n/i18n-util'
// import { loadLocaleAsync } from './i18n/i18n-util.async'
import { loadLocale } from './i18n/i18n-util.sync'
import Router from "./router"
import { useSpeechSynthesis } from 'react-speech-kit';

import { useAtom, useSetAtom } from 'jotai'
import { INIT, runtimeConfigAtom, gridAtom, langAtom, resetRuntimeConfigAtom, speechConfigAtom } from './state'
import { RuntimeConfigData } from './coreTypes';
import { getVoice } from './utils/lang'
import { register } from './utils/track'

let tim
function App(props: Partial<RuntimeConfigData>) {
   const [lang, setLang] = useAtom(langAtom)
   const setRuntimeConfig = useSetAtom(runtimeConfigAtom)
   const setSpeechConf = useSetAtom(speechConfigAtom)
   const setGrid = useSetAtom(gridAtom)
   const [resetConfig, setResetConfig] = useAtom(resetRuntimeConfigAtom)
   const { supported, voices } = useSpeechSynthesis({})

   // habitat(widget) props
   useEffect(() => {
      // console.log(props)
      if (resetConfig) {
         setTimeout(() => {
            setGrid(INIT)
            setRuntimeConfig({
               //  overrideUserdata: false
               ...(process.env.PREACT_APP_REMOTE_URL && { baseURL: process.env.PREACT_APP_REMOTE_URL }),
               ...(process.env.PREACT_APP_OVERRIDE_USERDATA && { overrideUserdata: process.env.PREACT_APP_OVERRIDE_USERDATA }),
               ...props,
            })
            setResetConfig(false)
         }, 500) // hardcpde value TODO: (calculate localstorage value to load)
      }
      register(props.meta)
   }, [props, resetConfig])

   // Detect locale
   // (Use as advanaced locale detection strategy as you like.
   // More info: https://github.com/ivanhofer/typesafe-i18n/tree/main/packages/detectors)
   let locale
   if (typeof window !== "undefined") {
      locale = lang != '' ? lang : detectLocale(navigatorDetector)
   } else {
      locale = lang != '' ? lang : 'en'
   }
   // console.log(lang, locale)

   // Load locales
   // (Use a data fetching solution that you prefer)
   const [localesLoaded, setLocalesLoaded] = useState(false)
   useEffect(() => {
      // console.log(locale)
      if (locale != '') {
         // loadLocaleAsync(locale).then(() => setLocalesLoaded(true))
         loadLocale(locale)
         setLocalesLoaded(true)
      }
      clearTimeout(tim)
      tim = setTimeout(() => lang == '' && setLang(locale), 2000)
   }, [locale])

   if (!localesLoaded) {
      return null
   }

   useEffect(() => {
      const index = getVoice(locale)
      setSpeechConf(a => ({ ...a, voiceIndex: index }))
   }, [voices, locale])

   return (
      <TypesafeI18n locale={locale}>
         <Router />
      </TypesafeI18n>
   )
}

export default App