import { Suspense, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom, } from "jotai";
import { Responsive, WidthProvider } from "react-grid-layout";
import GridCell from "src/components/atoms/gridCell";
import { isObjectEmpty, omitNullish } from "src/utils/object"
import { breakpointAtom, colsAtom, editModeAtom, gridAtom, runtimeConfigAtom, screenNameAtom } from "src/state";
import { Path } from "src/coreTypes";
import createModal from "src/components/molecules/createModal";
import TilePropsForm from "./tilePropsForm";
import { getScreen } from "src/utils/crud";
import { track } from "src/utils/track";

const getXY = (n: number, a: number) => {
    return { y: Math.floor(n / a), x: n % a }
}

const getData = (layout) => {
    let col = -1, row = -1
    layout.forEach(el => {
        if (el.x > col) col = el.x
        if (el.y > row) row = el.y
    })

    return [col + 1, row + 1]
}

const iterObj = (obj) => {
    return Object.entries(obj).map(([k, v]) => [k, getData(v)])
}

function usePartial(step: number) {
    const [value, setN] = useState(0)
    const r = useRef<any>()


    function reset(max: number) {
        setN(step)
        clearTimeout(r.current)
        r.current = setInterval(() => action(max), 50)
    }

    function action(max: number) {
        setN(value => {
            //LOG: console.log('max', value)
            if (value >= max) {
                clearInterval(r.current)
                return value
            }
            return value + step
        })
    }

    return [value, reset] as [number, (n: number) => void]
}


// console.log(dataFile, data, dataGrouped)
const Grid = ({ className, ...rest }) => {
    const editMode = useAtomValue(editModeAtom)
    const screenName = useAtomValue(screenNameAtom)
    const [grid, setGrid] = useAtom(gridAtom)
    const setCols = useSetAtom(colsAtom)
    const [breakpoint, setBreakpoint] = useAtom(breakpointAtom)
    const global = useAtomValue(runtimeConfigAtom)
    const [val, res] = usePartial(100)
    // console.log(grid)

    const tileModal = createModal('tiles-props', false)
    const bpLabels = Object.keys(global.grid.cols)

    const getRenderItems = (name: string) => {
        let [screen, index] = getScreen(grid, name)

        const renderItems = screen?.children?.map((el, i) => {
            return <GridCell
                key={el.id}
                tileData={el}
                tilePath={[index, i] as Path}
                showModal={tileModal.showModal}
                draggable={editMode}
            />
        }) ?? []

        return renderItems
    }

    const getScreenData = (name: string) => {
        // console.log(name, grid) 
        // console.log(breakpoint)
        let [screen, index] = getScreen(grid, name)
        let grouped = {}
        for (const i in screen?.children) {
            const tile = screen?.children[i]
            grouped[tile.id] = i
        }

        return {
            index,
            screen,
            grouped,
        }
    }

    const getLayouts = (name: string, bp: string) => {
        let [screen, index] = getScreen(grid, name)

        let layouts = {}
        for (const [i, el] of (screen?.children.slice(0, val) ?? []).entries()) {
            for (const _bp of bpLabels) {
                if (!layouts[_bp]) layouts[_bp] = []
                if (el.grid && el.grid[_bp]) {
                    layouts[_bp].push({ i: el.id, ...el.grid[_bp] })
                } else if (_bp == 'lg' && Array.isArray(el.grid)) {
                    layouts['lg'].push({ i: el.id, ...el.grid })
                } else {
                    layouts[_bp].push({ i: el.id, w: 1, h: 1, ...getXY(i, global.grid.cols[_bp]) })
                }
            }
        }

        // console.log(JSON.stringify(iterObj(layouts)), layouts)
        return layouts
    }

    const init = grid.length > 0
    const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), [init, screenName]);

    const renderItems = useMemo(() => getRenderItems(screenName), [screenName, grid, editMode])
    const screenData = useMemo(() => getScreenData(screenName), [screenName, grid])

    useEffect(() => {
        res(renderItems.length)
    }, [screenName])

    const layouts = useMemo(() => getLayouts(screenName, breakpoint), [screenName, breakpoint, grid, val])


    // console.log(screen, screenData)
    return <>
        <tileModal.Modal>
            {(props) => <TilePropsForm {...props} />}
        </tileModal.Modal>
        <tileModal.Label />
        <ResponsiveGridLayout
            className={`layout relative ${editMode ? 'border border-dashed border-neutral rounded-md' : ''} ${className}`}
            style={{ minHeight: global.grid.height + 'px' }}
            layouts={layouts}
            isDraggable={editMode}
            isBounded={true}
            breakpoints={global.grid.bpx}
            cols={global.grid.cols}
            onBreakpointChange={(newBp, newCols) => {
                //LOG: console.log("new breakpoint", newBp, newCols)
                setCols(newCols)
                setBreakpoint(newBp)
                track('change breakpoint', {
                    breakpoint: newBp
                })
            }}
            onDragStop={(lc) => {
                track('drag', {
                    old: lc.oldItem,
                    new: lc.newItem,
                })
            }}
            onResizeStop={(lc) => {
                track('resize', {
                    old: lc.oldItem,
                    new: lc.newItem,
                })
            }}
            onLayoutChange={(currentLayout, allLayouts) => {
                const colsRows = getData(currentLayout)
                // console.log(JSON.stringify(iterObj(allLayouts)), allLayouts)

                let bp = ''
                for (const _bp in global.grid.cols) {
                    if (global.grid.cols[_bp] == colsRows[0]) bp = _bp
                }
                // console.log(bp, currentLayout)
                // console.log(grid)

                if (editMode && !isObjectEmpty(allLayouts) && allLayouts?.[bp]) {
                    // for (const _bp in allLayouts) {
                    const screen = grid[screenData.index].children
                    for (const tile of allLayouts[bp]) {
                        const { i, ...rest } = tile

                        const data = omitNullish(rest)
                        delete data.static
                        delete data.moved

                        const index = screenData.grouped[i]
                        // console.log(index,screen)
                        if (!screen[index].grid) screen[index].grid = {}
                        if (screen[index].grid.x) screen[index].grid = { lg: screen[index].grid }
                        screen[index].grid[bp] = data
                        // }
                    }
                    grid[screenData.index].children = screen

                    // // console.log(out)
                    // if (!isObjectEmpty(out)) {
                    //     grid[screenData.index].children = Object.values(out)
                    setGrid(grid) // Caution if needed to get value in a reactive manner
                    // }
                    track('layout change', {
                        length: currentLayout.length
                    })
                }
            }}
            // onResize={() => {
            // const event = new Event("changeFont");
            // window.dispatchEvent(event);
            // }}
            draggableCancel=".resizable-handler"
            resizeHandle={
                <span className="resizable-handler absolute right-0 bottom-0 z-20 cursor-se-resize pb-1 pr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M 17.303301,6.6966992 V 17.303301 H 6.6966992" />
                    </svg>

                </span>}
            rowHeight={global.grid.height}
            // width={1500}
            // useCSSTransforms={true}
            minW={global.grid.minW}
            minH={global.grid.minH}
            maxW={global.grid.maxW}
            maxH={global.grid.maxH}
        >
            {renderItems.slice(0, val)}
        </ResponsiveGridLayout>
    </>
}

export default Grid