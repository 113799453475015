
import { useEffect } from "react";
import { useAtom } from "jotai";
import { Locales, useI18nContext } from "src/i18n/i18n-react";
import { loadLocale } from "src/i18n/i18n-util.sync";
import { langAtom } from "src/state";

export const useLang = () => {
    const { setLocale } = useI18nContext()
    const _lang = useAtom(langAtom)
    const [lang, setLang] = _lang

    useEffect(() => {
        if (lang == '') {
            return
        }

        loadLocale(lang as Locales)
        setLocale(lang as Locales)

        // loadLocale(lang)
    }, [lang])

    return _lang
}