import { useLang } from "src/hooks/useLang"
import { useI18nContext } from "src/i18n/i18n-react"
import { locales } from "src/i18n/i18n-util"
import { useSpeechSynthesis } from 'react-speech-kit';
import { useAtom } from "jotai";
import { gridAtom, runtimeConfigAtom, speechConfigAtom, themeAtom } from "src/state";
import { RESET } from "jotai/utils";
import { HTMLAttributes, useState } from "react";
import createModal from "src/components/molecules/createModal";
import { FileFormat, ScreenElement, Element } from "src/coreTypes";
import { downloadFile, readFile } from "src/utils/file";
import { getScreen } from "src/utils/crud";
import DiffView from "../molecules/diffView";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { track } from "src/utils/track";
import { range } from "src/utils/data";

interface SettingsFormProps extends HTMLAttributes<HTMLDivElement> {

}

const errorVariants = {
    'error': 'text-error',
    'warning': 'text-warning',
    'success': 'text-success'
}

type ImportConfData = {
    type: string,
    value: any,
    oldValue: any,
    file: File,
    action: () => void
}

const SettingsForm = ({ className, ...rest }: SettingsFormProps) => {
    const { LL } = useI18nContext()
    const [lang, setLang] = useLang()
    const [theme, setTheme] = useAtom(themeAtom)
    const { supported, voices } = useSpeechSynthesis({})
    const [config, setConfig] = useAtom(speechConfigAtom)
    const [grid, setGrid] = useAtom(gridAtom)
    const [runtime, setRuntime] = useAtom(runtimeConfigAtom)

    const [importConf, setImportConf] = useState<ImportConfData>(null)
    const [importMsg, setImportMsg] = useState({ value: '', type: '' })
    const [textMsg, setTextMsg] = useState<SyntaxError>(null)

    const resetModal = createModal('reset-modal')
    const importModal = createModal('import-modal', !!importConf)

    const importAction = (event) => {
        importConf.action()
        setImportConf(null)
        const file = importConf.file
        track('import file', {
            type: importConf.type,
            file: {
                name: file.name,
                size: file.size,
                mime: file.type,
                lastModified: file.lastModified,
            }
        })
    }

    const exportAction = (event) => {
        const date = (new Date()).toISOString()
        const outFile: FileFormat = {
            createdAt: date,
            type: 'grid',
            content: grid
        }

        const filename = `grid-${date.split('.')[0]}.json`
        const file = downloadFile(JSON.stringify(outFile), filename)
        track('download grid', {
            filename: filename,
            length: grid.length,
            file: {
                name: file.name,
                size: file.size,
                mime: file.type,
                lastModified: file.lastModified,
            }
        })
    }

    const readFileAction = (event) => {
        setImportMsg({ type: '', value: '' })
        const fileList: FileList = event.target.files;
        // console.log(fileList);
        if (fileList.length == 0) {
            return
        }
        const file = fileList[0]
        const outMsg = readFile(file, (event) => {
            try {
                const data: FileFormat = JSON.parse(event.target.result)
                // console.log(data)
                switch (data.type) {
                    case 'grid':
                        const newGrid = data.content as Element[]
                        console.log('grid file, screens:', newGrid.length)
                        //alert
                        // console.log('alert')
                        setImportConf({
                            type: 'grid',
                            value: newGrid,
                            oldValue: grid,
                            file: file,
                            action: () => {
                                setGrid(newGrid)
                                setImportMsg({ value: 'success', type: 'sucesss' })
                            }
                        })
                        break
                    case 'screen':
                        const screen = data.content as ScreenElement
                        console.log('screen file:', screen.id)
                        const [oldScreen, index] = getScreen(grid, screen.id)
                        if (index == -1) {
                            setGrid(grid.concat(screen))
                            setImportMsg({ value: 'success', type: 'sucesss' })
                        } else {
                            // altert
                            // console.log('alert')
                            setImportConf({
                                type: 'screen',
                                value: screen,
                                oldValue: oldScreen,
                                file: file,
                                action: () => {
                                    grid[index] = screen
                                    setGrid([...grid])
                                    setImportMsg({ value: 'success', type: 'sucesss' })
                                }
                            })
                        }
                        break
                    default:
                        console.log('file format not reconized')
                        setImportMsg({ value: 'formatNotFound', type: 'error' })
                }
            } catch (err) {
                console.log("JSON parse file error", err)
                setImportMsg({ value: 'badJSON', type: 'error' })
            }
        })
        if (outMsg != '') {
            setImportMsg({ value: outMsg, type: 'error' })
        }
    }

    const changeTheme = (e: any) => {
        const _theme = e.target.checked ? "light" : "dark"
        setTheme(_theme)
        track('change theme', {
            theme: _theme
        })
    }

    const changeLang = (e: any) => {
        const _lang = e.target.value
        setLang(_lang)
        track("change lang", {
            lang: _lang
        })
    }

    const changeVoice = (e: any) => {
        const index = parseInt(e.target.value)
        setConfig({ ...config, voiceIndex: index })
        track('change voice', {
            voice: voices[index],
            index: index
        })
    }

    // console.log(voices, config)
    const resetConfigs = () => {
        setGrid(RESET)
        setRuntime(RESET)
        track('reset config', {
            old: runtime
        })
    }

    const changeConfigs = (e: any) => {
        try {
            setTextMsg(null)
            const data = JSON.parse(e.target.value)
            setRuntime(data)
            track('change configs', {
                old: runtime,
                new: data,
            })
        } catch (err) {
            // console.log(err)
            setTextMsg(err)
        }
    }

    // console.log(config)
    return <div className={`relative ${className || ''}`} {...rest}>
        <a href="/" class="btn btn-sm btn-ghost btn-circle absolute right-2 top-2 text-xl">✕</a>
        <h1 className="font-bold text-3xl my-4 uppercase">{LL.common.settings()}</h1>
        <div className="form-control mb-4">
            <label className="label">
                <span className="label-text uppercase">{LL.common.language()}</span>
            </label>
            <div className="input-group">
                <select className="select select-bordered w-full capitalize" value={lang} onChange={changeLang}>
                    {locales.map((item, i) =>
                        <option
                            key={i}
                            className={`capitalize`}
                            value={item}>
                            {LL.common.lang[item]()}
                        </option>
                    )}
                </select>
            </div>
        </div>
        <div className="form-control mb-4">
            <label className="label">
                <span className="label-text uppercase">{LL.common.theme()}</span>
            </label>
            <div className="flex">
                <MoonIcon className="w-6" />
                <input type="checkbox" className="toggle mx-2" checked={theme == 'light'} onChange={changeTheme} />
                <SunIcon className="w-6" />
            </div>
        </div>
        <div className="form-control mb-4">
            <label className="label">
                <span className="label-text uppercase">{LL.aac.voice()}</span>
            </label>
            <div className="input-group">
                <select className="select select-bordered" value={config.voiceIndex} onChange={changeVoice}                >
                    {!supported ?
                        <option disabled selected>{LL.aac.notSupported()}</option>
                        :
                        voices.map((item, i) =>
                            <option
                                key={i}
                                className={``}
                                value={i}>
                                {`${item.name} (${item.lang})`}
                            </option>
                        )}
                </select>
            </div>
        </div>
        <div className="form-control2 mb-4">
            <div className="mb-4">
                <label className="label">
                    <span className="label-text uppercase">{LL.aac.pitch()}</span>
                </label>
                {/* <span>{LL.aac.pitch()}</span> */}
                <input type="range" min="0" max="2" className="range" step=".2"
                    value={config.pitch}
                    onChange={(e: any) => setConfig({ ...config, pitch: parseFloat(e.target.value) })}
                />
                <div className="w-full flex justify-between text-xs px-2">
                    {range(0, 2.2, .2).map((v: number) => <span>{v.toFixed(1)}</span>)}
                </div>
            </div>

        </div>
        <div class="form-control mb-4">
            <label class="label">
                <span class="label-text uppercase">{LL.aac.rate()}</span>
            </label>
            <label class="input-group">
                <input type="number" placeholder="1" min="0" max="10" step=".1" class="input input-bordered"
                    value={config.rate}
                    onChange={(e: any) => setConfig({ ...config, rate: parseFloat(e.target.value == 0 ? 0.1 : e.target.value) })}
                />
                <span>x</span>
            </label>
        </div>
        <div class="form-control mb-4">
            <label class="label cursor-pointer">
                <span class="label-text uppercase">{LL.aac.speakWords()}</span>
                <input type="checkbox" class="toggle" checked={config.speakWord} onChange={(e: any) => setConfig({ ...config, speakWord: e.target.checked })} />
            </label>
        </div>
        <div className="mb-4">
            <label class="label">
                <span class="label-text uppercase">{LL.common.importLabel()}</span>
            </label>
            <input type="file" class="file-input w-full max-w-xs" accept=".json" onChange={readFileAction} />
            <span className={`text-success ${importMsg.value != '' ? '' : 'hidden'} 
            ${errorVariants[importMsg.type]}`}>{LL.common[importMsg.value]() + '!'}</span>
            <importModal.Label></importModal.Label>
            <importModal.Modal
                className="overflow-y-auto !max-w-max overflow-x-auto"
                onChange={(ev) => {
                    if (!ev.target.checked) {
                        setImportConf(null)
                    }
                }}
            >
                {({ label }) => <>
                    <h3 className="font-bold text-lg uppercase">{LL.aac.caution()}</h3>
                    <p className="py-4">{LL.aac.importCaution()}</p>
                    <div className="modal-action my-2">
                        <label htmlFor={label} className="btn btn-error" onClick={importAction}>{LL.aac.actions.yes()}</label>
                        <label htmlFor={label} className="btn">{LL.aac.actions.no()}</label>
                    </div>
                    {importConf &&
                        <div className="collapse collapse-arrow">
                            <input type="checkbox" />
                            <div className="collapse-title text-sm uppercase">
                                {LL.common.seeDetails()}
                            </div>
                            <div className="collapse-content">
                                <DiffView {...importConf} />
                            </div>
                        </div>
                    }
                </>}
            </importModal.Modal>
        </div>
        <div className="form-control mb-4">
            <label class="label">
                <span class="label-text uppercase">{LL.common.exportLabel()}</span>
            </label>
            <button className="btn btn-primary w-56" onClick={exportAction}>{LL.common.export()}</button>
        </div>
        <div className="form-control my-4">
            <label className="label">
                <span className="label-text uppercase">{LL.aac.resetInfo()}</span>
            </label>

            <resetModal.Label role="button" className="btn btn-warning w-56 relative overflow-hidden justify-start">
                <span className="ml-6">{LL.aac.reset()}</span>
                <div className="-skew-x-12 h-full flex absolute -right-1">
                    <div className="h-full bg-black ml-6 w-6"></div>
                    <div className="h-full bg-black ml-6 w-6"></div>
                </div>
            </resetModal.Label>
            <resetModal.Modal>
                {({ label }) => <>
                    <h3 className="font-bold text-lg uppercase">{LL.aac.caution()}</h3>
                    <p className="py-4">{LL.aac.resetCaution()}</p>
                    <div className="modal-action">
                        <label htmlFor={label} className="btn btn-error" onClick={resetConfigs}>{LL.aac.actions.yes()}</label>
                        <label htmlFor={label} className="btn">{LL.aac.actions.no()}</label>
                    </div>
                </>}
            </resetModal.Modal>
        </div>
        <div className="form-control2 mb-4">
            <div className="collapse collapse-arrow">
                <input type="checkbox" />
                <div className="collapse-title text-sm uppercase">
                    {LL.common.advancedConfig()}
                </div>
                <div className="collapse-content">
                    <div class="mockup-code bg-base-200 text-base-content px-4">
                        {textMsg && <span className="pb-4">{`${textMsg.name}: ${textMsg.message}`}</span>}
                        <textarea
                            name=""
                            id=""
                            // cols="30"
                            rows={"20" as any}
                            className={`textarea numbered textarea-ghost w-full font-mono bg-transparent 
                            scrollbar-thin scrollbar-thumb-base-content scrollbar-base-100`}
                            defaultValue={JSON.stringify(runtime, null, ' ')}
                            onChange={changeConfigs}
                        >
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div className="my-10">
            <h2 className="font-bold font-mono">
                {LL.common.madeBy({ label: '' })}
                <a className="link mr-1" href="https://epi3.co">epi3.co</a>
                {` © ${Math.max(2023, (new Date()).getFullYear())}`}
            </h2>
        </div>
    </div>
}

export default SettingsForm