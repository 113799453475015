import { S3Client } from "@aws-sdk/client-s3";
import { PutObjectCommand, } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';

export const imagesTypes = {
    'image/png': 'png',
    'image/jpeg': 'jpg',
    'image/webp': 'webp'
}

export const client = new S3Client({
    region: process.env.PREACT_APP_AWS_REGION,
    credentials: {
        accessKeyId: process.env.PREACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.PREACT_APP_AWS_SECRET_ACCESS_KEY
    },
});


export const uploadFile = async (file: File) => {
    if (!Object.keys(imagesTypes).includes(file.type)) {
        console.log(file.name, file.type)
        return { error: `not valid file format: ${file.type}` }
    }

    const key = `media/images/${uuidv4()}.${imagesTypes[file.type]}`
    const command = new PutObjectCommand({
        Bucket: process.env.PREACT_APP_S3_BUCKET,
        Key: key,
        Body: file,
        ContentType: file.type,
        // ACL: 'public-read',
    })

    try {
        const response = await client.send(command);
        console.log(response);
        const url = `${process.env.PREACT_APP_REMOTE_URL}/${key}`
        return { response, key, url }
    } catch (err) {
        console.error(err);
        return { error: err }
    }
}
