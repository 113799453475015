import { HTMLAttributes } from "react"

interface DefaultLayoutProps extends HTMLAttributes<HTMLDivElement> {

}
const DefaultLayout = ({ children, ...rest }: DefaultLayoutProps) => {

    return <div className="h-full max-h-screen scrollbar-thin scrollbar-thumb-base-content scrollbar-base-100 overflow-y-auto" {...rest}>
        {children}
    </div>
}

export default DefaultLayout