import deepDiff from "deep-diff"

function toObject(arr) {
    return arr.reduce((obj, cur) => ({
        ...obj,
        [cur.id]: cur
    }), {})
}

export const diffElements = (g1, g2) => {

    const gg1 = Array.isArray(g1) ? toObject(g1) : g1
    const gg2 = Array.isArray(g2) ? toObject(g2) : g2

    const out = deepDiff(gg1, gg2, {
        normalize: (path, key, lhs, rhs) => {
            if (key == 'children') {
                // console.log(key, path, rhs, lhs)
                return [toObject(lhs), toObject(rhs)] as any
            }
            return false
        }
    })
    return out
}
