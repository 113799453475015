import { render } from "react"
import Wrapper from "./wrapper";

if (process.env.NODE_ENV === 'development') {
  // Enable preact devtools
  // eslint-disable-next-line import/no-unassigned-import
  require('preact/devtools');
}

const container = document.getElementById("app");

render(<Wrapper />, container, container.lastChild)

// Hot Module Replacement
if (module.hot) {
  module.hot.accept();
}