import { MetaData } from "src/coreTypes";
import mixpanel from 'mixpanel-browser';

const eventTrackEnabled = process.env.PREACT_APP_MIXPANEL_TOKEN &&
    (process.env.NODE_ENV == 'production' || process.env.PREACT_APP_LOCAL_TRACK)

export function initialize() {
    if (eventTrackEnabled) {
        mixpanel.init(process.env.PREACT_APP_MIXPANEL_TOKEN, {
            debug: process.env.NODE_ENV != 'production',
            ...(process.env.PREACT_APP_MIXPANEL_CUSTOM_URL && {api_host: process.env.PREACT_APP_MIXPANEL_CUSTOM_URL}),
            track_pageview: true,
        });
        mixpanel.set_config({ 'persistence': 'localStorage' })
        // mixpanel.identify('sytabaresa@gmail.com')
        // mixpanel.track('Signed Up', {
        //     'Signup Type': 'Referral'
        // })
    }
}

export function register(meta: MetaData) {
    if (!eventTrackEnabled) return
    const { id, ...rest } = meta || {}
    if (id) {
        mixpanel.identify(meta.id)
        mixpanel.people.set({
            ...rest
        })
    } else {
        mixpanel.register({
            ...rest
        })
    }
}

export function deregister(meta: MetaData) {
    if (!eventTrackEnabled) return
    mixpanel.reset();
}

export function track(eventName: string, props: Record<string, any> = null, ops: Record<string, any> = null, ...rest: any[]) {
    if (!eventTrackEnabled) return
    if (eventName == 'page') {
        mixpanel.track_pageview(props)
    }

    if (eventName == 'links') {
        const { selector, event, ...rest } = props || {}
        mixpanel.track_links(selector, event, rest)
    }
    mixpanel.track(eventName, props, ops, ...rest)
}

export function trackTimer(eventName: string) {
    if (!eventTrackEnabled) return
    mixpanel.time_event(eventName);
}

// export function trackLink(e) {
//     // console.log(e)
//     // track('page', { page: e.target.href })
// }