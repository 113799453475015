import { fetchWrapper } from "./fetch"
const token = 'X0vjEUN6KRlxbp2DoUkyHeM0VOmxY91rA6BbU5j3Xu6wDodwS0McmilLPBWDUcJ1' //generic not private token

export async function getImageOptions(search: string) {

    const result1 = await fetchWrapper.get('https://iconfinder-api-auth.herokuapp.com//v4/icons/search?' + new URLSearchParams({
        query: search,
        count: '10',
        premium: 'false',
        style: 'filled-outline',
        is_explicit: 'false',
        // vector: 'false',
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
            "accept": "application/json",
        }
    })
    // console.log(result)

    const result2 = await fetchWrapper.get('https://iconfinder-api-auth.herokuapp.com//v4/icons/search?' + new URLSearchParams({
        query: search,
        count: '10',
        premium: 'false',
        style: 'flat',
        is_explicit: 'false',
        // vector: 'false',
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
            "accept": "application/json",
        }
    })

    return [...result1.data.icons, ...result2.data.icons]
}