import { Path, ScreenElement } from "src/coreTypes"
import { Element } from "src/coreTypes"
import { get, has, set } from "./object"

function _getPath(path: Path) {
    return path.map(p => [p, 'children']).flat().slice(0, -1)
}

export function pathExist(root: Element[], path: Path) {
    return has(root, _getPath(path))
}
export function getNode(root: Element[], path: Path) {
    return get(root, _getPath(path))
}

export function setNode(root: Element[], node: Element, path: Path) {
    return set(root, _getPath(path), node)
}

// export function getPath(root: Element[], ) {
//     findIndex(screens, ['id', name])
// }

export function getScreen(root: Element[], id: string) {
    const index = root.findIndex((el: Element) => el.id == id && el.type == 'screen')
    // console.log(index)
    return [root?.[index], index] as [ScreenElement, number]
}

export function createScreen(id: string) {
    return { type: 'screen', id, children: [] } as ScreenElement
}
