import { XMarkIcon } from "@heroicons/react/24/outline"
import { HTMLAttributes, useMemo, useState } from "react"
import { useI18nContext } from "src/i18n/i18n-react"
import { testStorage } from "src/utils/storage"

interface AlertErrorProps extends HTMLAttributes<HTMLDivElement> {

}

const AlertError = ({ className, style, ...rest }: AlertErrorProps) => {
    const { LL } = useI18nContext()
    const validStorage = useMemo(() => testStorage(), [])
    const [errorDisplay, setErrorDisplay] = useState(!validStorage)


    return errorDisplay && <div className="toast">
        <div className="alert alert-error">
            {LL.common.localStorageNotSupported()}
            <button className="btn btn-ghost btn-square btn-xs" onClick={() => setErrorDisplay(false)}><XMarkIcon /></button>
        </div>
    </div>
}


export default AlertError