// @ts-check

/**
 * @typedef { import('../i18n-types').BaseTranslation } BaseTranslation
 */

/** @type { BaseTranslation } */
const es = {
    // TODO: your translations go here
    common: {
        menu: 'menu',
        settings: 'configuraciones',
        language: 'lenguaje',
        theme: 'tema',
        save: 'guardar',
        share: 'compartir',
        shareScreen: 'compartir pantalla',
        export: 'exportar',
        exportLabel: 'exportar todos las patallas',
        import: 'importar',
        importLabel: 'importar una pantalla o conjunto completo de pantallas',
        importCaution: 'Está seguro de importar y reemplazar los datos de {type:string}?',
        success: 'Hecho',
        notJSON: 'no es un archivo con extensión JSON (.json)',
        badJSON: 'no es un archivo JSON válido',
        formatNotFound: 'formato de archivo no reconocido (pantalla o pantallas)',
        madeBy: 'hecho con ❤ por {label}',
        advancedConfig: 'Configuración Avanzada',
        seeDetails: 'ver detalles',
        lang: {
            es: 'español',
            en: 'inglés'
        },
        close: 'cerrar',
        localStorageNotSupported: "Pictoaccess funciona mejor activando cookies y/o localStorage"
    },
    importTable: {
        kind: 'tipo',
        old: 'valor antiguo',
        new: 'valor nuevo',
        screen: 'pantalla',
        tile: 'mosaico',
        path: 'ruta',
        D: 'borrar',
        E: 'editar',
        N: 'nuevo',
        noDiff: 'no hay diferencias'
    },
    aac: {
        space: 'espacio',
        screen: 'pantalla',
        grid: 'Pantallas',
        new: 'nuevo',
        sure: 'seguro',
        remove: 'eliminar',
        voice: 'voz',
        run: 'reproducir',
        speak: 'hablar',
        copy: 'copiar',
        copied: 'copiado',
        clear: 'borrar',
        cancel: 'cancelar',
        reset: 'restablecer',
        caution: 'atención',
        notSupported: 'Síntesis de voz no soportada :(',
        resetInfo: 'Restablecer configuraciones de fábrica & mosaicos',
        resetCaution: 'Estás seguro de reiniciar todas las configuraciones y mosaicos?',
        importCaution: 'Estás seguro de reemplazar, crear o borrar los siguientes mosaicos?',
        pitch: 'tono',
        rate: 'velocidad',
        edit: 'editar',
        done: 'hecho',
        back: 'atrás',
        speakWords: 'vocalizar cada mosaico',
        actions: {
            yes: 'yes',
            no: 'no'
        },
        fields: {
            id: 'ID',
            imgUrl: 'URL imagen',
            img: 'imagen',
            url: 'url',
            text: 'texto',
            color: 'color',
            searchIcon: 'buscar imagen',
            link: 'colección',
            link_help: 'Puedes seleccionar otra coleccion para acceder a ella, o escribir un link web para abrirlo en una nueva pestaña'
        }
    }
}

export default es