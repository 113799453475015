import SettingsForm from "src/components/organisms/settingsForm"
import DefaultLayout from "src/layouts/default"


const SettingsPage = (props) => {

    // const Grid = useMemo(() => <Grid2 />, []);
    return <DefaultLayout>
        <div className="flex flex-col items-center">
            <SettingsForm className="w-full md:w-2/3 lg:w-1/2 px-4" />
        </div>
    </DefaultLayout>
}

export default SettingsPage