import { FileFormat, ScreenElement, WorkspaceFormat } from "src/coreTypes";
import { fetchWrapper } from "src/utils/fetch";

export async function getGridData(options: { url: string, workspace?: string }): Promise<ScreenElement[]> {
    const url = options.url.slice(-1)[0] != '/' ? options.url + '/' : options.url
    const res = await fetchWrapper.get(url + `workspaces/${options.workspace ?? 'default'}.json`, {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    }) as { data: WorkspaceFormat }
    // console.log(res)

    const resources = await Promise.all(Object.entries(res.data.content).map(async ([label, resource]) => {
        let f: FileFormat
        if (typeof resource == 'string') {
            const resourceRes = await fetchWrapper.get(resource, {
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                }
            }) as { data: FileFormat }
            f = resourceRes.data
        } else {
            f = resource
        }
        return f
    }))
    // console.log(resources)

    const elements = resources.map(f => f.content)

    const preOut = elements.flat() as ScreenElement[]
    // console.log(preOut)

    const outScreens = {}
    preOut.forEach(o => {
        outScreens[o.id] = o
    })

    //LOG: console.log(outScreens)
    return Object.values(outScreens)
}

export function getUnix(date: Date) {
    return Math.round(date.getTime() / 1000)
}
export function range(start: number, end: number, step: number) {
    var len = start;
    if (end != undefined) {
        len = Math.floor(Math.abs(start - end) / step);
    }
    step = step === undefined ? 1 : step;
    return new Array(len).fill(0).map(function (el, i) {
        return start + i * step;
    });
};