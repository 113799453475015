import * as Sentry from "@sentry/react";
import { initialize } from "./utils/track";

if (
    process.env.PREACT_APP_SENTRY_DSN &&
    (process.env.NODE_ENV == 'production' || process.env.PREACT_APP_LOCAL_SENTRY)
) {
    // console.log(process.env.PREACT_APP_SENTRY_DSN)
    Sentry.init({
        integrations: [
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/assets\.einv\.tech\//],
            }),
            new Sentry.Replay()
        ],
        dsn: process.env.PREACT_APP_SENTRY_DSN,
        // debug: true,
        // integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the trans actions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}


// track initialize
initialize()
