import { ArrowRightIcon } from "@heroicons/react/24/outline"
import { Diff, DiffDeleted, DiffNew } from "deep-diff"
import { useEffect, useState } from "react"
import { useI18nContext } from "src/i18n/i18n-react"
import { diffElements } from "src/utils/diff"

interface DiffViewProps {
    // diffArray: Diff<any, any>[]
    value: any[]
    oldValue: any[]
    type: string
}

const kindMap = {
    D: 'bg-red-100',
    E: 'bg-amber-100',
    N: 'bg-green-100'
}

const DiffView = ({ value, oldValue, type }: DiffViewProps) => {
    const { LL } = useI18nContext()
    const [diff, setDiff] = useState<Diff<any, any>[]>(null)

    // console.log(value, oldValue)
    useEffect(() => {
        if (oldValue && value) {
            const d = diffElements(oldValue, value)
            console.log(d)
            setDiff(d)
        }
    }, [value, oldValue])


    const getPath = (path: string[]) => {
        let out: Record<string, string>
        switch (type) {
            case 'grid':
                out = {
                    screen: path[0],
                    tile: path[2],
                    path: '/' + path.slice(3).join('/')
                }

                break
            case 'screen':
                out = {
                    tile: '',
                    path: '/'
                }
                break
        }
        return out
    }


    return diff ? <div className="overflow-auto scrollbar-thin scrollbar-thumb-base-content scrollbar-base-100">
        <table className="table w-full">
            <thead>
                <tr>
                    <th className="bg-primary"></th>
                    <th className="bg-primary">{LL.importTable.kind()}</th>
                    {/* <th>path</th> */}
                    {Object.entries(getPath(['', '', '', '']))
                        .map(([k, v]) =>
                            <th className="bg-primary">{LL.importTable[k]()}</th>
                        )}
                    <th className="bg-primary">{LL.importTable.old()}</th>
                    <th className="bg-primary"></th>
                    <th className="bg-primary">{LL.importTable.new()}</th>
                </tr>
            </thead>
            <tbody>
                {diff?.map((row, index) => {
                    const newPath = getPath(row.path)
                    const lhs = JSON.stringify((row as DiffDeleted<any>).lhs, null, '\t')
                    const rhs = JSON.stringify((row as DiffNew<any>).rhs, null, '\t')

                    return <tr>
                        <th className={`border-base-content`}>{index + 1}</th>
                        <td className={`border-base-content`}>
                            <span className={`badge badge-outline font-bold uppercase`}>
                                {LL.importTable[row.kind]()}
                            </span>
                        </td>
                        {Object.entries(getPath(row.path))
                            .map(([k, v]) =>
                                <td className={`border-base-content`}>{v && v != '' ? v : '/'}</td>
                            )}
                        <td className={`border-base-content ${lhs ? kindMap[row.kind] : ''}`}>
                            <pre className="max-w-lg overflow-hidden">{lhs ?? 'null'}</pre>
                        </td>
                        <td className="border-base-content"><ArrowRightIcon className="h-6" /></td>
                        <td className={`border-base-content ${rhs ? kindMap[row.kind] : ''}`}>
                            <pre className="max-w-lg overflow-hidden">{rhs ?? 'null'}</pre>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    </div> :
        <div>
            <span className="badge badge-primary py-4">{LL.importTable.noDiff()}</span>
        </div>

}

export default DiffView