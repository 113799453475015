// @ts-check

/**
 * @typedef { import('../i18n-types').BaseTranslation } BaseTranslation
 */

/** @type { BaseTranslation } */
const en = {
    // TODO: your translations go here
    common: {
        menu: 'menu',
        settings: 'settings',
        language: 'language',
        theme: 'theme',
        save: 'save',
        share: 'share',
        shareScreen: 'share screen',
        export: 'export',
        exportLabel: 'export all screens',
        import: 'import',
        importLabel: 'import a screen or a full configuration of screens',
        importCaution: 'Are you sure you want to import and remplace {type:string} data? ',
        success: 'success',
        notJSON: 'not a JSON file (.json)',
        badJSON: 'not a valid JSON file',
        formatNotFound: 'file format not reconized (screen or grid)',
        madeBy: 'made with ❤ by {label}',
        advancedConfig: 'Advanced Configuration',
        seeDetails: 'see details',
        lang: {
            es: 'spanish',
            en: 'english'
        },
        close: 'close',
        localStorageNotSupported: "Pictoaccess works better activating cookies and/or localStorage"
    },
    importTable: {
        kind: 'kind',
        old: 'old value',
        new: 'new value',
        screen: 'screen',
        tile: 'tile',
        path: 'path',
        D: 'delete',
        E: 'edit',
        N: 'new',
        noDiff: 'no differences'
    },
    aac: {
        space: 'space',
        screen: 'screen',
        grid: 'grid',
        new: 'new',
        remove: 'remove',
        sure: 'sure',
        voice: 'voice',
        run: 'run',
        speak: 'speak',
        copy: 'copy',
        copied: 'copied',
        clear: 'clear',
        cancel: 'cancel',
        reset: 'reset',
        caution: 'caution',
        notSupported: 'Speech Synthesis not supported :(',
        resetInfo: 'reset all settings & tiles',
        resetCaution: 'Are you sure you want to reset all settings & mosaics?',
        importCaution: 'Are you sure to replace, create or delete this mosaics?',
        pitch: 'pitch',
        rate: 'rate',
        edit: 'edit',
        done: 'done',
        back: 'back',
        speakWords: 'speak each tile',
        actions: {
            yes: 'yes',
            no: 'no'
        },
        fields: {
            id: 'ID',
            imgUrl: 'URL image',
            text: 'text',
            img: 'image',
            url: 'url',
            color: 'color',
            searchIcon: 'search image',
            link: 'collection',
            link_help: 'You can select another collection to link to it, or write a weblink to open it in a new tab'
        }
    }
}

export default en