import { ComponentChildren } from "preact";
import React, { HTMLAttributes, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useI18nContext } from "src/i18n/i18n-react";

interface ModalContainerProps extends HTMLAttributes<HTMLLabelElement> {
    withClose?: boolean;
    modalChild?: ComponentChildren;
    modalName?: string;
    onChange?: (e: any) => void
    children: ComponentChildren | ((props: { modalState: boolean, showModal: (state: boolean) => void, label: string }) => ComponentChildren)
};

const createModal = (modalName: string, defaultValue = false) => {

    const finalModalName = !modalName || modalName == '' ? `modal-${Math.random().toString().slice(-5)}` : modalName
    const closeRef = useRef<HTMLAttributes<HTMLLabelElement>>()
    const triggerRef = useRef<HTMLAttributes<HTMLLabelElement>>()
    const modalRef = useRef<HTMLAttributes<HTMLLabelElement>>()
    const modalStateRef = useRef<HTMLAttributes<HTMLInputElement>>()

    const labelProps = {
        tabIndex: 0,
        onKeyPress: e => {
            e.stopPropagation()
            if (e.key == "Enter")
                e.target.click()
        }
    }

    function onKey(ev) {
        // console.log(ev)
        // if escape pressed
        if (ev.which == 27) {
            (closeRef.current as any).click()
            return
        }

        // if tab or shift-tab pressed
        if (ev.which == 9) {

            // get list of all children elements in given object
            const focusableItems = [...(modalRef.current as any).querySelectorAll('*')]
            // console.log(focusableItems)

            // get currently focused item
            const focusedItem = document.activeElement

            // get the index of the currently focused item
            const focusedItemIndex = focusableItems.indexOf(focusedItem);
            // console.log(focusedItemIndex)

            if (ev.shiftKey) {
                //back tab
                // if focused on first item and user preses back-tab, go to the last focusable item
                if (focusedItemIndex == 0) {
                    focusableItems[focusableItems.length - 1].focus();
                    ev.preventDefault();
                }

            } else {
                //forward tab
                // if focused on the last item and user preses tab, go to the first focusable item
                if (focusedItemIndex >= focusableItems.length - 1) {
                    focusableItems[0].focus();
                    ev.preventDefault();
                }
            }
            return
        }
    }

    function onClose(ev) {
        (triggerRef.current as any).focus()
    }

    const Label = (props: HTMLAttributes<HTMLLabelElement> | any) => {

        return <label
            {...labelProps}
            ref={triggerRef}
            htmlFor={finalModalName}
            onClick={e => {
                setTimeout(() => (closeRef.current as any).focus(), 500)
            }}
            {...props}>
        </label>
    }

    const modalState = modalStateRef.current?.checked as boolean
    // const showModal = (state: boolean) => {
    //   if (modalStateRef.current) {
    //     console.log(modalStateRef, state)
    //     modalStateRef.current.value = !!state
    //   }
    // }

    const Modal = ({
        modalChild,
        children,
        withClose = true,
        className,
        onChange,
        ...rest
    }: ModalContainerProps) => {
        const { LL } = useI18nContext()
        const [_defModal, showModal] = useState(defaultValue)

        const out = <>
            <input
                type="checkbox"
                id={finalModalName}
                ref={modalStateRef as any}
                defaultChecked={_defModal}
                onChange={onChange}
                className="modal-toggle hidden"
            />
            <label htmlFor={finalModalName} className={`modal cursor-pointer`}>
                <label htmlFor=""
                    ref={modalRef as any}
                    tabIndex={0}
                    onKeyDown={onKey}
                    className={`modal-box scrollbar-thin scrollbar-thumb-base-content scrollbar-base-100 ${className || ''}`}
                    {...rest}
                >
                    {withClose &&
                        <label
                            {...labelProps}
                            ref={closeRef as any}
                            htmlFor={finalModalName}
                            onClick={onClose}
                            className="btn btn-sm btn-ghost absolute right-2 top-2"
                            aria-label={LL.common.close()}
                        >
                            ✕
                        </label>
                    }
                    {typeof children == 'function' ? children({ modalState, showModal, label: finalModalName }) : children}
                </label>
            </label>
        </>

        if (typeof document == 'undefined') {
            return out
        }

        return out //createPortal(out, document?.body)
    };

    const showModal = () => {
        (triggerRef as any).current.click()
    }

    return { Modal, Label, modalState, showModal, label: finalModalName }
}

export default createModal;
