import { useI18nContext } from 'src/i18n/i18n-react'
import SpeakBar from "src/components/molecules/speakBar"
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { _inputAtom, defaultScreen, editModeAtom, gridAtom, inputAtom, screenNameAtom, speechConfigAtom } from 'src/state';
import { useSpeechSynthesis } from 'react-speech-kit';
import { RESET } from 'jotai/utils';
import { useLang } from 'src/hooks/useLang';
import { TrashIcon, PlayIcon, XCircleIcon, ClipboardIcon, ArrowUturnLeftIcon, BackspaceIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline"
import { HTMLAttributes, useEffect, useState } from 'react';
import { getBarText, getCapitalizedText } from 'src/utils/text';
import { copyToClipboard, isIframe } from 'src/utils/dom';
import { sendMessageTop } from 'src/utils/messages';
import { track } from 'src/utils/track';

interface InputBarProps extends HTMLAttributes<HTMLDivElement> {

}

const InputBar = ({ className }: InputBarProps) => {
    const { LL } = useI18nContext()
    const [lang] = useLang()
    const speechConf = useAtomValue(speechConfigAtom)
    const editMode = useAtomValue(editModeAtom)
    const [input, setInput] = useAtom(inputAtom)
    const setBackInput = useSetAtom(_inputAtom)
    const [screenName, setScreenName] = useAtom(screenNameAtom)
    const [copied, setCopied] = useState(false)

    const onEnd = () => {
        // You could do something here after speaking has finished
    };
    const onResult = () => {
        // You could do something here after speaking has finished
    };

    const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis({
        onEnd,
        onResult,
    });

    const speakText = (text) => {
        const voice = voices[speechConf.voiceIndex] || null;

        // const text = 'Hola mundo!'
        // console.log(text)

        speak({
            text,
            voice,
            rate: speechConf.rate,
            pitch: speechConf.pitch
        })
    }

    useEffect(() => {
        if (speechConf.speakWord && input.length > 0) {
            const lastWord = input.slice(-1)[0]
            const text = getBarText(lastWord, lang)
            // console.log('word', text, lastWord)
            speakText(text == ' ' ? LL.aac.space() : text)
        }
    }, [input])

    const runAction = () => {
        // console.log(input)
        const text = getBarText(input, lang)

        // console.log(text)
        if (text && text != '') {
            speakText(text)
        }
        track('speak', {
            text: text,
            screen: screenName
        })
    }

    const copyAction = async (event) => {
        event.preventDefault()
        const selText = getCapitalizedText(getBarText(input, lang))

        if (isIframe()) {
            sendMessageTop({ type: 'clipboard', data: selText })
            return
        }

        copyToClipboard(selText)
        track('copy to clipboard', {
            text: selText,
            screen: screenName
        })
    }

    const clear = () => {
        setInput(RESET)
        cancel()
        const selText = getBarText(input, lang)

        track('cancel', {
            text: selText,
            screen: screenName,
        })
    }

    const removeLast = () => {
        const last = input.slice(-1)[0]
        setBackInput(input.slice(0, -1))
        track('remove last', {
            last,
            screen: screenName
        })
    }

    const copy = (e) => {
        setCopied(true)
        copyAction(e)
    }

    const copyExit = () => {
        setCopied(false)
    }

    const back = () => {
        setScreenName(defaultScreen)
        track('page', {
            screen: screenName
        })
    }

    return <div className={`flex font-bold items-center justify-center flex-wrap ${className ?? ''}`}>
        {/* {screenName != defaultScreen &&
            <button className='btn inbar-btn btn-primary p-2 mx-2 mt-2 tooltip tooltip-bottom hidden lg:block' data-tip={LL.aac.back()} aria-label={LL.aac.back()} onClick={back}><ArrowUturnLeftIcon /></button>} */}
        <SpeakBar
            className="flex-1 mx-2 mt-2 h-32 cursor-pointer hover:bg-base-100 hover:border-2 hover:text-base-content
            hover:grayscale active:grayscale [--btn-focus-scale:0.98] relative group min-w-full xs:min-w-0
            scrollbar-thin scrollbar-thumb-base-content scrollbar-base-100 overflow-y-auto"
            tiles={input}
            onClick={removeLast}
            disabled={!supported}
        >
            <div className="absolute bottom-0 right-0 mr-2 mb-2">
                <BackspaceIcon className="w-10 h-10 group-hover:opacity-70 opacity-30 text-base-content" />
            </div>
        </SpeakBar>
        <div className="mt-2 xs:w-auto flex xs:flex-col xs:h-32 lg:h-auto lg:flex-row justify-center">
            <div className="flex flex-row-reverse xs:flex-row lg:flex-row-reverse">
                {supported ? (!speaking ?
                    <button className='btn inbar-btn btn-primary p-2 mr-2 mb-1 mb:mb-0 tooltip tooltip-bottom'
                        data-tip={LL.aac.run()}
                        aria-label={LL.aac.run()}
                        onClick={runAction}
                        disabled={editMode}>
                        <PlayIcon />
                    </button>
                    :
                    <button className='btn inbar-btn btn-warning p-2 mr-2 mb-1 mb:mb-0 tooltip tooltip-bottom'
                        data-tip={LL.aac.cancel()}
                        aria-label={LL.aac.cancel()}
                        onClick={cancel}>
                        <XCircleIcon />
                    </button>
                ) :
                    <button className='btn inbar-btn btn-neutral p-2 mr-2 mb-1 mb:mb-0 tooltip tooltip-bottom'
                        data-tip={LL.aac.notSupported()}
                        aria-label={LL.aac.notSupported()}>
                        <QuestionMarkCircleIcon />
                    </button>
                }
                <button className={`btn inbar-btn btn-primary p-2 mr-2 tooltip tooltip-bottom ${screenName != defaultScreen ? '' : 'invisible lg:hidden'}`}
                    data-tip={LL.aac.back()}
                    aria-label={LL.aac.back()}
                    onClick={back}>
                    <ArrowUturnLeftIcon />
                </button>
            </div>
            <div>
                <button className='btn inbar-btn btn-error p-2 mr-2 mb-1 mb:mb-0 tooltip tooltip-bottom'
                    data-tip={LL.aac.clear()}
                    aria-label={LL.aac.clear()}
                    onClick={clear}
                    disabled={editMode}>
                    <TrashIcon />
                </button>
                <button className='btn inbar-btn btn-secondary p-2 mr-2 tooltip tooltip-bottom'
                    data-tip={copied ? LL.aac.copied() + '!' : LL.aac.copy()}
                    aria-label={LL.aac.copy()}
                    onClick={copy}
                    onBlur={copyExit}
                    disabled={editMode}
                >
                    <ClipboardIcon />
                </button>
            </div>
        </div>
    </div>
}

export default InputBar