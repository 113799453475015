import Router, { CustomHistory, RouterOnChangeArgs, route } from 'preact-router';
import GridPage from "./pages/grid.page"
import SettingsPage from './pages/settings.page';
import { createHashHistory } from "history"
import { track } from './utils/track';

const RouterComp = () => {

  const handleRoute = async (e: RouterOnChangeArgs<Record<string, string>>) => {
    // switch (e.url) {
    //   case '/profile':
    //     const isAuthed = await this.isAuthenticated();
    //     if (!isAuthed) route('/', true);
    //     break;
    // }
    track('page', {
      url: e.url,
      page: e.path,
      // active: e.active,
      current: e.current,
      previous: e.previous,
      matches: e.matches
    })
  };

  return <Router
    history={createHashHistory() as unknown as CustomHistory}
    onChange={handleRoute}
  >
    <GridPage path="/" />
    <SettingsPage path="/settings" />
    {/* <About path="/about" /> */}
    {/* // Advanced is an optional query
    <Search path="/search/:query/:advanced?" /> */}
  </Router>
}

export default RouterComp