import { getUnix } from "./data";

export function downloadFile(content: string, fileName: string, type = 'application/json') {

    const blob = new Blob([content], { type })
    const urlForDownload = window.URL.createObjectURL(blob)
    const linkElement = document.createElement('a'); // create hyperlink HTML element

    linkElement.href = urlForDownload
    linkElement.download = fileName
    linkElement.click()

    URL.revokeObjectURL(urlForDownload) // free memory
    return {
        name: fileName,
        size: blob.size,
        type: type,
        lastModified: getUnix(new Date())
    } as Partial<File>
}

export function readFile(file: File, callback: (event) => void) {
    // Check if the file is a JSON file.
    if (file.type && file.type != 'application/json') {
        console.log('File is not an JSON File.', file.type, file);
        return 'notJSON';
    }

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
        callback(event)
    });

    reader.addEventListener('progress', (event) => {
        if (event.loaded && event.total) {
            const percent = (event.loaded / event.total) * 100;
            console.log(`Progress: ${Math.round(percent)}`);
        }
    });

    reader.readAsText(file);
    return ''
}