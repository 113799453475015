import { atomWithStorage as _atomWithStorage, createJSONStorage } from "jotai/utils";
import { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";
import { testStorage } from "./storage";

export const defaultStorage = createJSONStorage<any>(() => {

    if (!testStorage()) {
        return {
            getItem: (key: string, initialValue: any) => initialValue,
            setItem: (key: string, newValue: any) => null
        }
    }
    return typeof window !== 'undefined'
        ? window.localStorage
        : (undefined as unknown as Storage)
})

export function atomWithStorage<Value>(key: string, initialValue: Value, storage?: SyncStorage<Value>, ops?: any) {
    return _atomWithStorage<Value>(key, initialValue, defaultStorage, ops)
}