import Grid from "src/components/organisms/grid"
import GridMenu from "src/components/molecules/gridMenu"
import InputBar from "src/components/organisms/inputBar"
import DefaultLayout from "src/layouts/default"
import AlertError from "src/components/atoms/alertError"


const GridPage = (props) => {
    //WARNING: not include i18n hooks from here (uknown error)
    // const dimMode = useAtomValue(dimModeAtom)

    // const Grid = useMemo(() => <Grid2 />, []);
    return <DefaultLayout>
        <div className="relative h-screen m-auto xl:w-4/5 2xl:w-2/3 w-full overflow-x-hidden xl:overflow-x-visible flex flex-col">
            <GridMenu className="p-0 absolute top-0 left-0 z-20 flex w-0 h-0" />
            {/* ${dimMode ? 'blur-[2px]' : ''} */}
            <div className={`flex flex-col relative overflow-hidden`}>
                {/* <div className={`z-10 absolute w-full h-full opacity-50 bg-black ${dimMode ? '' : 'hidden'}`}></div> */}
                <InputBar className="p-2 w-full bg-base-100 z-10" />
                <div className="flex-1 p-2 pt-0 scrollbar-thin scrollbar-thumb-base-content scrollbar-base-100 overflow-y-auto">
                    <Grid className="" />
                </div>
            </div>
            <AlertError />
        </div>

    </DefaultLayout>
}

export default GridPage