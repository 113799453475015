import InputCell from 'src/components/atoms/inputCell';
import { TileData } from 'src/coreTypes';
import { HTMLAttributes } from 'preact/compat';

interface SpeakBarProps extends HTMLAttributes<HTMLButtonElement> {
    tiles: TileData[]
}
const SpeakBar = ({ className, children, tiles, ...rest }: SpeakBarProps) => {

    return <button className={`btn btn-outline flex flex-row flex-wrap px-4 ${className}`} {...rest}>
        {tiles.map(item =>
            <InputCell tileData={item} />
        )}
        {children}
    </button>
}


export default SpeakBar