import { HTMLAttributes, Suspense, forwardRef, useEffect, useRef } from "react"
import { generateForegroundColorFrom, getColor } from 'src/utils/colors'
import { Path, TileData } from "src/coreTypes"
import { useLang } from "src/hooks/useLang"
import { getText } from "src/utils/lang"
import { Cog6ToothIcon, ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon, Squares2X2Icon } from "@heroicons/react/24/outline"
import { useAtom, useSetAtom } from "jotai"
import { currentPathAtom, gridAtom, inputAtom, screenNameAtom } from "src/state"
import { createScreen, getScreen } from "src/utils/crud"
import { isValidHttpUrl } from "src/utils/validate"
import { getFontSize } from "src/utils/text"
import { track } from "src/utils/track"

interface GridCellProps extends HTMLAttributes<HTMLDivElement> {
    tileData: TileData;
    draggable: boolean;
    showModal: () => void;
    tilePath: Path;
}

const GridCell = ({ className, tileData: tile, draggable, tilePath, showModal, children, ...rest }: GridCellProps, ref) => {
    // console.log(rest)
    const [lang] = useLang()
    const [screenName, setScreenName] = useAtom(screenNameAtom)
    const setInput = useSetAtom(inputAtom)
    const setPath = useSetAtom(currentPathAtom)
    const [grid, setGrid] = useAtom(gridAtom)
    const titleRef = useRef<HTMLHeadingElement>()

    const color = getColor(tile.color, true)
    const textColor = generateForegroundColorFrom(color)
    const text = getText(tile, lang)
    // console.log(tile.color, color, textColor)

    const mainAction = (e) => {
        track('tile click', {
            id: tile.id,
            screen: screenName
        })
        if (tile.link && tile.link != '') {
            if (isValidHttpUrl(tile.link)) {
                track('open external link', {
                    tileId: tile.id,
                    link: tile.link
                })
                window.open(tile.link, '_blank');
            } else {
                let [screen, index] = getScreen(grid, tile.link)

                if (!screen) {
                    // console.log('new screen:', tileData.link)
                    screen = createScreen(tile.link)
                    setGrid(grid.concat(screen))
                    track('create screen', {
                        id: screen.id,
                        tileId: tile.id,
                        screen: screenName
                    })
                }
                setScreenName(tile.link)
                track('go to screen', {
                    tileId: tile.id,
                    screen: screenName
                })
            }
        } else {
            const [screen] = getScreen(grid, screenName)
            setInput({ ...tile, speakOptions: { ...screen.speakOptions, ...tile.speakOptions } })
            track('tile add text', {
                tileId: tile.id,
                screen: screenName
            })
        }
    }

    const showSettings = () => {
        showModal()
        setPath(tilePath)
        track('show settings', {
            id: tile.id,
            screen: screenName
        })
    }

    const height = parseInt((rest.style as any).height?.split('px')[0])
    const width = parseInt((rest.style as any).width?.split('px')[0])
    const withImage = tile.imgUrl && tile.imgUrl != ''
    const padding = 8

    useEffect(() => {
        // console.log(width, titleRef.current.clientWidth)
        titleRef.current.style.fontSize = Math.min(getFontSize(titleRef.current.clientWidth, withImage ? .6 : .5), height - 2 * padding) + 'px'
    }, [width])


    return <div
        ref={ref}
        // style={{ ...style, }}
        className={`relative ${className || ''}`}
        {...rest}
    >
        <button
            className={`relative grid items-stretch btn !animate-none justify-center p-2 w-full h-full 
            group active:border-2 hover:border-2 focus:border-2 shadow-md
            ${draggable ? 'border-dashed' : ''}`}
            style={{
                backgroundColor: color,
                color: textColor,
                borderColor: textColor,
                gridTemplateColumns: `repeat(auto-fit, minmax(${Math.min(width, height) - 2 * padding + 'px' || '5rem'}, 1fr))`,
                gridTemplateRows: 'auto fit-content(25%)'
            }}
            alt-label={text}
            {
            ...(!draggable && { onclick: mainAction })
            }
        >
            {tile.link && tile.link != '' &&
                <div onMouseDown={mainAction} onTouchStart={mainAction} className="absolute top-0 right-0 pr-1 pt-1 z-10">
                    <Squares2X2Icon className="w-4" />
                </div>
            }
            {draggable &&
                <div onMouseDown={showSettings} onTouchStart={showSettings} className="absolute top-0 left-0 pl-1 pt-1 z-10">
                    <Cog6ToothIcon className="w-4" />
                </div>
            }
            {draggable && <>
                {/* <div className="absolute top-0 right-0 mt-1 mr-1 w-4 h-4 border border-neutral rounded-full" /> */}
                {/* <HandRaisedIcon className="group-hover:block hidden absolute mr-1 w-16 opacity-30" /> */}
                <ArrowDownIcon className="group-hover:block group-active:block hidden opacity-50 absolute bottom-0 h-4 w-full" />
                <ArrowUpIcon className="group-hover:block group-active:block hidden opacity-50 absolute top-0 h-4 w-full" />
                <ArrowLeftIcon className="group-hover:block group-active:block hidden opacity-50 absolute left-0 w-4 h-full" />
                <ArrowRightIcon className="group-hover:block group-active:block hidden opacity-50 absolute right-0 w-4 h-full" />

            </>}
            {draggable && children}
            {tile.imgUrl && tile.imgUrl != '' ?
                <>
                    <div className="p-2 bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${tile.imgUrl})` }} >
                    </div>
                    {/* <img loading="lazy" className="w-full object-contain overflow-auto" src={tile.imgUrl} alt={text} /> */}
                    <div className="pt-2 max-w-full flex items-center">
                        <h2 ref={titleRef} className="uppercase break-words leading-none flex-1 w-full"
                            htmlFor={'img-' + tile.text}
                        >
                            {text}
                        </h2>
                    </div>
                </>
                :
                <div className="p-0 flex items-center">
                    <h2 ref={titleRef} className="uppercase break-words leading-none flex-1 w-full">
                        {text}
                    </h2>
                </div>
            }
            {/* <div class="card-actions justify-end">
      <button class="btn btn-primary">move</button>
    </div> */}
        </button>
    </div>
}


export default forwardRef(GridCell)