
const Splash = () => {
    return <div className="absolute top-0 left-0 w-full h-screen bg-base-100 flex items-center justify-center z-50">
        <div className="flex flex-col items-center">
            <img src={new URL('../../assets/images/logo.png', import.meta.url) as unknown as string} className="w-24" alt="picto access logo" />
            <h1 className="font-bold text-2xl">Picto Access</h1>
        </div>
    </div>
}

export default Splash