export async function loadjscssfile(filename, filetype) {
    return new Promise((resolve, reject) => {
        let fileref
        if (filetype == "js") { //if filename is a external JavaScript file
            fileref = document.createElement('script')
            fileref.setAttribute("type", "text/javascript")
            fileref.setAttribute("src", filename)
        }
        else if (filetype == "css") { //if filename is an external CSS file
            fileref = document.createElement("link")
            fileref.setAttribute("rel", "stylesheet")
            fileref.setAttribute("type", "text/css")
            fileref.setAttribute("href", filename)
        }
        if (typeof fileref != "undefined") {
            document.getElementsByTagName("head")[0].appendChild(fileref)

            if (typeof fileref.onload != 'undefined') {
                fileref.onload = f;
            } else {
                // old browsers
                var img = document.createElement("img");
                img.onerror = function () {
                    f();
                    document.body.removeChild(img);
                }
                document.body.appendChild(img);
                img.src = filename;
            }

            function f() {
                resolve(true)
            }
        } else {
            reject()
        }
    })
}

export function css(element, style) {
    for (const property in style) {
        if (style[property] === '')
            continue
        element.style[property] = style[property];
    }
}

export function traduceFileInput() {

}

export function isIframe() {
    return window.location !== window.parent.location
}

export async function copyToClipboard(text: string) {
    try {
        if (!navigator.clipboard) {
            // IE support
            (window as any).clipboardData.setData('Text', text);
        } else {
            await navigator.clipboard.writeText(text);
        }
    } catch {
        console.log("copy into clipboard not suported in this browser :(")
        return false
    }
    console.log('text copied:', text)
    return true
}

export function isVisible(el) {
    var style = window.getComputedStyle(el);
    return style.visibility != 'hidden'
}