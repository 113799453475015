import { useEffect, useState } from "react";
import App from "src/app";
import 'src/common'
import Splash from "src/components/atoms/splash";
import { RuntimeConfigData, WindowMessage } from "src/coreTypes";
import "src/styles/web.css";
import { sendMessageTop } from "src/utils/messages";

const Wrapper = () => {
    const [runtime, setRuntime] = useState<Partial<RuntimeConfigData>>(null)
    const [splash, setSplash] = useState(process.env.NODE_ENV == 'production')

    function receiveMessage() {
        window.onmessage = function (e: MessageEvent) {
            const msg = e.data as WindowMessage
            // console.log(msg)
            if (msg.type == 'runtime') {
                setRuntime(msg.data)
            }
            // sendMessage({ type: 'ack', data: msg.data })
        }
    }

    useEffect(() => {
        receiveMessage()
        sendMessageTop({ type: 'init', data: null })

        // non iframe case
        setTimeout(() => {
            !runtime && setRuntime({})
        }, 500)

        // splash time
        setTimeout(() => {
            setSplash(false)
        }, 1500)
    }, [])
    
    return <div className="relative">

        {splash && <Splash />}
        {!!runtime && <App {...runtime} />}
    </div>
}

export default Wrapper