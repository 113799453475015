import { CheckCircleIcon, PencilIcon, PlusIcon, ShareIcon, AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid"
import { useAtom, useAtomValue } from "jotai"
import { HTMLAttributes } from "preact/compat"
import { FileFormat, ScreenElement, TileElement } from "src/coreTypes"
import { useI18nContext } from "src/i18n/i18n-react"
import { colsAtom, editModeAtom, gridAtom, openMenuAtom, runtimeConfigAtom, screenNameAtom } from "src/state"
import { getScreen } from "src/utils/crud"
import { downloadFile } from "src/utils/file"
import { track } from "src/utils/track"

interface LayoutProps extends HTMLAttributes<HTMLDivElement> {

}

const Layout = ({ children, className, ...rest }: LayoutProps) => {
    const { LL } = useI18nContext()
    const [openMenu, setOpenMenu] = useAtom(openMenuAtom)
    const [editMode, setEditMode] = useAtom(editModeAtom)
    const [grid, setGrid] = useAtom(gridAtom)
    const currentScreen = useAtomValue(screenNameAtom)
    const cols = useAtomValue(colsAtom)
    const runtime = useAtomValue(runtimeConfigAtom)

    const editLabel = editMode ? LL.aac.done() : LL.aac.edit()

    const createAction = () => {
        // console.log(grid)
        let [screen, index] = getScreen(grid, currentScreen)
        if (!screen) {
            screen = { id: 'home', type: 'screen' } as ScreenElement
            index = 0
        }
        screen.children = screen?.children ?? []

        const id = `tile-${Math.random().toString().slice(-5)}`
        const tile: TileElement = {
            type: 'tile',
            id,
            text: '',
            i18n: {},
            color: '#fff',
            grid: {
                w: 1,
                h: 1,
                x: screen.children.length % (cols || 10),
                y: Infinity,
            }
        }
        screen.children.push(tile)
        grid[index] = screen
        setGrid([...grid])
        track('create tile', {
            id,
            tile,
            screen: screen.id
        })
    }

    const shareAction = () => {
        const [screen] = getScreen(grid, currentScreen)
        const date = (new Date()).toISOString()
        const outFile: FileFormat = {
            createdAt: date,
            type: 'screen',
            content: screen
        }

        const outName = `screen-${currentScreen}-${date.split('.')[0]}.json`
        const file = downloadFile(JSON.stringify(outFile), outName)
        track('download screen', {
            filename: outName,
            screen: screen.id,
            length: screen.children.length,
            file: {
                name: file.name,
                size: file.size,
                mime: file.type,
                lastModified: file.lastModified,
            }
        })
    }

    return <nav className={`navbar ${className}`} {...rest}>
        <div className={`dropdown ${editMode ? 'dropdown-open' : ''}`} onfocusin={() => setOpenMenu(true)} onfocusout={() => setOpenMenu(false)}>
            <label tabIndex={0}
                className={`btn btn-square bg-white hover:bg-gray-200 !flex-nowrap overflow-hidden ${editMode ? 'h-16' : 'h-14'} ${openMenu && !editMode ? 'w-64' : 'w-16'}
                rounded-t-none border-2 border-base-content 
            drop-shadow-lg tooltip tooltip-right flex mt-[-.7rem] ml-[-.3rem] xl:ml-[-.75rem] transition-all`} data-tip={LL.common.menu()}>
                <img src={runtime.logoUrl ?? new URL('../../assets/images/logo.png', import.meta.url) as unknown as string} className="w-9" alt="picto access logo" />
                <span className={`text-xl text-black font-extrabold transition-all truncate ${openMenu && !editMode ? 'delay-200' : 'absolute opacity-0 invisible'}`}>{runtime.logoLabel}</span>
            </label>
            <ul

                tabIndex={0}
                className="z-50 ml-1 xl:ml-[-.75rem] menu dropdown-content p-2 bg-base-100 mt-2 rounded-full border-2 border-base-content drop-shadow-lg"
            >
                <li>
                    <a role="button" className="tooltip tooltip-right p-1 uppercase font-bold" data-tip={editLabel} aria-label={editLabel}
                        onClick={(e) => { setEditMode(!editMode) }}>
                        {editMode ?
                            <CheckCircleIcon className="w-10" />
                            :
                            <PencilIcon className="w-9" />
                        }
                    </a>
                </li>
                {!editMode && <li>
                    <a role="button" href="/settings" className="tooltip tooltip-right px-1 uppercase font-bold" data-tip={LL.common.settings()} aria-label={LL.common.settings()}>
                        <AdjustmentsHorizontalIcon className="w-10" />
                    </a>
                </li>}
            </ul>

        </div>
        <div className={`z-50 ml-2 bg-base-100 flex items-center rounded-full border-2 border-base-content px-4 py-1 transition-all drop-shadow-lg ${editMode ? '' : 'invisible opacity-0'}`}>
            <button className="btn btn-square btn-ghost tooltip tooltip-bottom" data-tip={LL.aac.new()} aria-label={LL.aac.new()}
                onClick={createAction}>
                <PlusIcon />
            </button>
            <button className="btn btn-square btn-ghost tooltip tooltip-bottom" data-tip={LL.common.shareScreen()} aria-label={LL.common.shareScreen()}
                onClick={shareAction}>
                <ShareIcon className="h-10" />
            </button>
        </div>
    </nav>
}


export default Layout
