import { TileData } from "src/coreTypes";
import { Locale } from "typesafe-i18n/types/runtime/src/core.mjs";

export function getText(el: TileData, lang: Locale) {
    return el.i18n && el.i18n[lang]?.text ? el.i18n[lang].text : el.text
}

export function getVoice(lang: Locale) {
    const speechAPI = window.speechSynthesis
    const voices = speechAPI.getVoices()
    // console.log(voices, lang)
    return voices.findIndex((item) => item.lang.split('-')[0] == lang)
}