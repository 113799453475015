import { HTMLAttributes } from "preact/compat"
import { TileData } from "src/coreTypes"
import { useLang } from "src/hooks/useLang"
import { getText } from "src/utils/lang"

interface InputCellProps extends HTMLAttributes<HTMLDivElement> {
    tileData: TileData
}
const InputCell = ({ tileData, className, style, ...rest }: InputCellProps) => {
    const [lang] = useLang()
    const text = getText(tileData, lang)
    const renderText = text == ' ' ? <span className="mr-2"></span> : text
    // console.log(style)

    return <div
        className={`flex flex-col px-1 items-center justify-center ${className || ''}`}
        style={{ ...(style as {}), ...tileData.style }}
        {...rest}
    >
        {tileData.imgUrl && tileData.imgUrl != '' ?
            <>
                <figure className="flex-1 flex items-center">
                    <img id={'img-' + tileData.text} src={tileData.imgUrl} alt={text + ' logo'} className="w-9" />
                </figure>
                <div className="pt-2 w-full">
                    <h2 className="uppercase break-words text-center"
                        htmlFor={'img-' + tileData.text}>
                        {renderText}
                    </h2>
                </div>
            </>
            :
            <h2 className="font-bold text-2xl">{renderText}</h2>
        }
    </div>
}

export default InputCell