import { useAtom, useAtomValue } from "jotai"
import { useEffect, useMemo, useState } from "react"
import { useI18nContext } from "src/i18n/i18n-react"
import { currentPathAtom, gridAtom, screenNameAtom } from "src/state"
import { getText } from "src/utils/lang"
import { useLang } from "src/hooks/useLang"
import { getNode, getScreen, setNode } from "src/utils/crud"
import { HexColorPicker } from "react-colorful"
import { TileData, TileElement } from "src/coreTypes"
import { ScreenElement } from "src/coreTypes"
import { getColor } from "src/utils/colors"
import { uploadFile } from "src/utils/upload"
import { track } from "src/utils/track"
import { getImageOptions } from "src/utils/images"
import { debounce } from "src/utils/time"

interface TilePropsFormProps {
    modalState: boolean
    showModal: (a: boolean) => void
}
let time
const TilePropsForm = ({ modalState, showModal }: TilePropsFormProps) => {
    const { LL } = useI18nContext()
    const [lang] = useLang()
    const path = useAtomValue(currentPathAtom)
    const [grid, setGrid] = useAtom(gridAtom)
    const [defImg, setDefImg] = useState(true)
    const [remove, setRemove] = useState(false)
    const [data, setData] = useState<TileData>({} as TileData)
    const screenName = useAtomValue(screenNameAtom)
    const [error, setError] = useState('')
    const [inputErr, setInputErr] = useState('')
    const [searchIcons, setSearchIcons] = useState([])

    const screenSearch = grid
        .filter(g => !data?.link || g.id.toLowerCase().includes(data?.link.toLowerCase()))
        .filter(s => data?.link !== s.id)
        .slice(0, 5) as ScreenElement[]

    const tile = useMemo(() => getNode(grid, path) as TileData, [path])
    // console.log(data)

    useEffect(() => {
        // console.log('change tile', tile)
        if (tile) {
            setData({ ...tile, text: getText(tile, lang) })
        }
    }, [tile])
    // console.log(data)
    // console.log(path, tile)

    const updateData = e => {
        let value = e.target.value.trim()
        // console.log(e.target.name, value)
        setData({
            ...data,
            [e.target.name]: value
        })
    }

    const submit = (ev) => {
        ev.preventDefault()
        // console.log(JSON.stringify(data), data.text)
        const i18n = {
            ...tile?.i18n,
            [lang]: {
                ...tile?.i18n?.[lang],
                ...(data.text && { text: data.text })
            }
        }
        if (lang != 'en') {
            delete data?.text
        }
        // if (data.color) {
        //     data.color = /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(data.color) ? data.color : '#' + data.color
        // }

        const newTile = { ...tile, ...data, i18n } as TileElement
        // console.log(newTile, data, tile)

        setNode(grid, newTile, path)
        setGrid([...grid])
        showModal(false)
        track('tile edit', {
            old: tile,
            new: newTile,
            screen: screenName,
        })
    }

    const removeAction = (ev) => {
        clearTimeout(time)
        // console.log(ev.target.checked)
        if (ev.target.checked) {
            time = setTimeout(() => {
                const [screen, index] = getScreen(grid, screenName)
                screen.children = screen.children.filter(t => t.id != tile?.id)
                grid[index] = screen
                setGrid([...grid])
                showModal(false)
                track('remove tile', {
                    screen: screenName,
                    id: tile?.id
                })
            }, 1500)
        }
    }

    const uploadAction = async (event) => {
        setInputErr('')
        const file = event.target.files[0] as File

        const { response, key, url, error } = await uploadFile(file)
        if (error) {
            setInputErr(error)
            return
        }
        setData({ ...data, imgUrl: url })
    }

    const getItems = debounce(async (ev) => {
        const search = ev.target.value
        const items = await getImageOptions(search)
        // console.log(items)
        setSearchIcons(items)
    }, 2000)
    const searchImage = async (ev) => {
        getItems(ev)
    }

    return <div id="tile-cont" className="flex flex-col items-center overflow-visible">
        <div className="flex flex-col items-start w-full">
            <h2 className="font-bold uppercase text-2xl mb-4">{LL.aac.edit()}</h2>
            <div className="join mb-4 w-full">
                <label htmlFor="input-id" className="join-item input-label">
                    <span>{LL.aac.fields.id()}</span>
                </label>
                <input type="text" placeholder="..." id="input-id" name="id" value={data?.id ?? ''} onChange={updateData} className="join-item input input-bordered flex-1 w-full" />
            </div>
            <div className="mb-4 w-full">
                <h3 className="font-bold uppercase text-xl ">{LL.aac.fields.img()}</h3>
                <div className="join w-full mb-1">
                    <label htmlFor="input-img-url" className="join-item input-label">
                        <span>{LL.aac.fields.url()}</span>
                    </label>
                    <input type="text" placeholder="http://..." id="input-img-url" name="imgUrl" value={data?.imgUrl ?? ''} onChange={updateData} className="join-item input input-bordered flex-1 w-full" />
                    {data?.imgUrl && <span className={`join-item input-label transition-all ${defImg ? 'opacity-0 w-0 p-0' : 'w-16 opacity-100'}`} >
                        <img
                            onError={() => setDefImg(true)}
                            onLoad={() => setDefImg(false)}
                            src={data?.imgUrl}
                            className={`w-10 ${defImg ? 'hidden' : ''}`}
                            alt={data?.id}
                        />
                    </span>}
                    {/* <label className="input-group"> */}
                </div>
                <input type="file" onChange={uploadAction} className="file-input input-bordered w-full" />
                {inputErr && <span className="text-error">{JSON.stringify(inputErr)}</span>}
                <div className="join w-full my-1">
                    <label htmlFor="input-img-url" className="join-item input-label">
                        <span>{LL.aac.fields.searchIcon()}</span>
                    </label>
                    <input type="text" placeholder="search" onChange={searchImage} className="join-item input input-bordered flex-1 w-full" />
                </div>
                <div className="w-full overflow-y-hidden overflow-x-scroll flex scrollbar-thin scrollbar-thumb-base-content ">
                    {searchIcons.map((icon) => {
                        const img = icon?.raster_sizes?.filter(el => el?.size == 64)[0]
                        if (!img) return <></>
                        const url = img.formats[0]?.preview_url
                        if (!url) return <></>
                        return <button className="p-2 btn btn-ghost w-16 h-16" onClick={() => setData({
                            ...data,
                            imgUrl: url
                        })}>
                            <img src={url} className="" alt="" />
                        </button>
                    })}
                </div>
            </div>
            {/* </label> */}
            <div className="join mb-4 w-full">
                <label htmlFor="input-text" className="join-item input-label">
                    <span>{LL.aac.fields.text()}</span>
                </label>
                <input type="text" placeholder="" id="input-text" name="text" value={data?.text ?? ''} onChange={updateData} className="join-item input input-bordered flex-1 w-full" />
            </div>
            <div className="join mb-4 dropdown w-full">
                <label htmlFor="input-color" className="join-item input-label">
                    <span>{LL.aac.fields.color()}</span>
                </label>
                <div tabIndex={0} className={`dropdown-content card top-[-14rem] left-[5rem] bg-base-100 p-2 z-10 shadow-md `}  >
                    <HexColorPicker color={getColor(data?.color ?? '')} onChange={(color) => setData({ ...data, color })} className="" />
                </div>
                <input tabIndex={0} type="text" placeholder="#ABCDEF" id="input-color" name="color" value={data?.color ?? ''} onChange={updateData} className="join-item input input-bordered flex-1 w-full" />
                {data?.color && <span className={`join-item w-16 transition-all border border-base-content ${data?.color ? 'opacity-100' : 'opacity-0 w-0 p-0'}`}
                    style={{ backgroundColor: getColor(data?.color) }}
                >
                </span>}
            </div>
            <label className="join dropdown dropdown-top mb-4 w-full">
                <label htmlFor="input-link" className="join-item input-label">
                    <span>{LL.aac.fields.link()}</span>
                </label>
                {screenSearch.length > 0 && <ul tabIndex={0} class="dropdown-content menu menu-compact p-2 shadow bg-base-100 rounded-box w-52 z-10">
                    {screenSearch.map(s => <li>
                        <a onClick={() => setData({ ...data, link: s.id })}>{s.id}</a>
                    </li>
                    )}
                </ul>}
                <input tabIndex={0} type="text" placeholder="" id="input-link" name="link" value={data?.link ?? ''} onChange={updateData} className="join-item input input-bordered flex-1 w-full" />
                <div data-tip={LL.aac.fields.link_help()} className="tooltip tooltip-left">
                    <label className="join-item btn w-16 transition-all border border-base-content ">?</label>
                </div>
            </label>
            <div className="flex mb-4">
                <button className="btn btn-warning" onClick={() => setRemove(true)}>{LL.aac.remove()}</button>
                {<div className={`form-control transition-all ${remove ? '' : 'w-0 opacity-0'}`}>
                    <label className="label cursor-pointer">
                        <span className="label-text mx-4">{LL.aac.sure() + "?"}</span>
                        <input type="checkbox" className="toggle" onChange={removeAction} />
                    </label>
                </div>}
            </div>
        </div>
        <button onClick={submit} className="btn btn-primary">{LL.common.save()}</button>
    </div>

}

export default TilePropsForm