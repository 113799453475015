import { TileData } from "src/coreTypes"
import { Locale } from "typesafe-i18n/types/runtime/src/core.mjs"
import { getText } from "./lang"

export function getBarText(input: TileData | TileData[], lang: Locale) {
    if (Array.isArray(input))
        return input.reduce((prev, el) => {
            // console.log(el)
            return prev + (getText(el, lang) ?? '') + (el.speakOptions?.separator ?? ' ')
        }, '')
    else
        return getText(input, lang)
}

export function getCapitalizedText(text: string) {
    return text[0].toUpperCase() + text.substring(1).toLowerCase()
}


const addEvent = function (el, type, fn) {
    if (el.addEventListener)
        el.addEventListener(type, fn, false)
    else
        el.attachEvent('on' + type, fn)
}

const removeEvent = function (el, type, fn) {
    if (el.removeEventListener)
        el.removeEventListener(type, fn, false)
    else
        el.detachEvent('on' + type, fn)
}

const extend = function (obj, ext) {
    for (const key in ext)
        if (ext.hasOwnProperty(key))
            obj[key] = ext[key]
    return obj
}

export function getFontSize(width: number, compressor = 1, options = null) {
    const settings = extend({
        'minFontSize': -1 / 0,
        'maxFontSize': 1 / 0
    }, options)

    return Math.max(Math.min(width / (compressor * 10), parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize))
}